import React from "react";
import css from './InfoPedido.module.css'

function InfoPedido(props) {
    if (props.estado > 0)
        return (
            <div
                className={`${css.Contenedor} BackgroundColor ${css.TextoColor}`}>
                <div
                    className={css.TituloGrosor}>
                    SEGUIMIENTO DE ORDEN
                </div>
                <div
                    className={css.ContenedorInfo}>
                    <div
                        className={css.ContenedorNumeroOrder}>
                        <div
                            className={css.SubtituloGrosor}>
                            Número de pedido
                        </div>
                        <div
                            className={css.codigo}>
                            {props.orden.codigoOrden}
                        </div>
                    </div>
                    <div
                        className={css.ContenedorDireccion}>
                        <div
                            className={css.SubtituloGrosor}>
                            Dirección de entrega
                        </div>
                        <div
                            className={css.direccion}>
                            {props.orden.direccion}
                        </div>

                    </div>
                </div>
            </div>
        );
    return (<div />);
}

export { InfoPedido }