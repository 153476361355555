import React from 'react';
import css from './Estilos.module.css'

function Transito(props) {

    return (
        <div
            className={` ${props.estado} ${css.contenedorEstado}`}>
            <div
                className={`${props.estilos} ${css.info}`}>
                <div
                    className={`${props.estilos} ${css.titulo}`}
                >
                    En transito
                </div>

                {props.estado >= 4 ?
                    <div
                        className={`${props.estilos} ${css.infoDiseno}`}
                    >
                        <div>
                            Guía: {props.guiaTrans}
                        </div>
                        <div>
                            Transportadora: {props.transportadora}
                        </div>
                        {
                            props.fechaEstimadaEntrega ?
                                <div>
                                    Fecha estimada de entrega: {props.fechaEstimadaEntrega}
                                </div> :
                                null
                        }

                    </div>
                    :
                    null}
            </div>
        </div>
    );
}

export { Transito };