import React from 'react';
import css from './Estilos.module.css'

function Entregado(props) {
    return (
        <div
            className={` ${props.estado} ${css.contenedorEstado}`}>
            <div
                className={`${props.estilos} ${css.info} `}>
                <div
                    className={`${props.estilos}  ${css.titulo}`}>
                    Entregado
                </div>
                {props.estado >= 5 ?
                    <div
                        className={`${props.estilos}`}>
                        {props.fechaEntrega}
                    </div>
                    :
                    null
                }
            </div>

        </div>

    );
}

export { Entregado };