import config from '../APIConfig.json';

async function GeneralFetch(metodo, sufijoUrl) {
    var datos = null, error = false, mensaje = null;
    var respuesta = null;

    respuesta = await fetch(`${config.apiUrl}${sufijoUrl}`,
        {
            method: metodo,
            headers: {
                'Accept': "application/json, text/plain, */*",
                'Content-Type': "application/json;charset=utf-8",
            }
        });   
    
    if (respuesta.ok) {
        datos = await respuesta.json();
    } else if (respuesta.status === 404){
        datos = null;
        mensaje = await respuesta.text();
    } else {
        error = true;
        mensaje = await respuesta.text();
    }

    return await{ datos, error, mensaje }
}

export { GeneralFetch }