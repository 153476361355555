import React from 'react';
import css from './Estilos.module.css'

function Alistamiento(props) {

    return (
        <div
            className={`${props.estado}  ${css.contenedorEstado}`}>
            <div
                className={`${props.estilos}  ${css.titulo} ${css.info}`}
            >
                Alistamiento
            </div>
        </div>
    );
}

export { Alistamiento };