import React from 'react';
import css from './Estilos.module.css'

function ConfirmacionPedido(props) {
    return (
        <div
            className={` ${props.estado}  ${css.contenedorEstado}`}>
            <div
                className={`${props.estilos} ${css.info}`}>
                <div>
                    <div
                        className={`${props.estilos}  ${css.titulo}`}
                    >
                        Confirmación de pedido
                    </div>
                </div>
                {
                    props.estado >= 2 ?
                        <div
                            className={`${props.estilos} `}
                        >
                            {props.fechaEstimadaDespacho ? `Fecha estimada de despacho: ${props.fechaEstimadaDespacho}` : null}
                        </div>
                        :
                        null
                }

            </div>


        </div>

    );
}

export { ConfirmacionPedido };