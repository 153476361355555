import lookAndField from '../LookAndField.json'

const marcas = [ "GEF", "PUNTOBLANCO", "GALAX", "BABYFRESH" ];

function logoMarca(tipoMarca) {
    let backgroundImg = null;

    backgroundImg = lookAndField.find(e => e.marca === tipoMarca).icono;
    return backgroundImg;
}

function sizeLogo(marca) {
    let design = null;
    switch (marca) {
        case "GEF":
            design = "logoGef";
            break;
        case "PUNTOBLANCO":
            design = "logoPuntoBlanco";
            break;
        case "PARFOIS":
            design = "logoParfois";
            break;
        case "BABYFRESH":
            design = "logoBabyFresh";
            break;
        case "GALAX":
            design = "logoGalax";
            break;
        default: design = "";
            break;
    }

    return design;
}

function espacioCodigoOrden(codigo) {
    var codigoConSaltos = "";
    if (codigo == undefined) {
        return null
    }

    for (let index = 0; index < codigo.length; index++) {
        if ((index >= 10 && index < 11) || (index >= 20 && index < 21) || index == 30)
            codigoConSaltos = codigoConSaltos + codigo[index] + "\n";
        else
            codigoConSaltos = codigoConSaltos + codigo[index];
    }

    return codigoConSaltos;
}

function valorEstado(estado) {
    switch (estado) {
        case "F":
            return 1;
        case "R":
            return 2;
        case "P":
            return 3;
        case "T":
            return 4;
        case "S":
            return 5;
        default:
            return 0;
    }
}

function EstiloEstado(estado, componente) {
    if (estado >= componente) {
        return "Activado";
    } else {
        return "Desactivado"
    }
}

function obtenerCodigoOrden(url){
    if (url.startsWith("/"))
        return url.substring(1);
    return url;
}

function obtenerMarcaDesdeUrl(url){
    var elementos = url.split(".");
    if (elementos.length){
        var marca = elementos.find(x => marcas.some(m => m === x.toUpperCase()));
        if (marca)
            return marca.toUpperCase();
    }
    return null;
}

export { logoMarca, sizeLogo, valorEstado, EstiloEstado, espacioCodigoOrden, obtenerMarcaDesdeUrl, obtenerCodigoOrden }