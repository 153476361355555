import React from 'react';

import css from './Estilos.module.css'


function Aprobado(props) {

    return (
        <div
            className={` ${css.contenedorEstado}`}>
           
            <div
                className={`${props.estilos}  ${css.titulo} ${css.info} `}
            >
                Aprobado
            </div>
        </div>
    );
}

export { Aprobado };