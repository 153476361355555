import React from 'react';
import { Aprobado } from '../Estados/Aprobado';
import { ConfirmacionPedido } from '../Estados/ConfirmacionPedido';
import { Transito } from '../Estados/Transito';
import { Alistamiento } from '../Estados/Alistamiento';
import { Entregado } from '../Estados/Entregado';
import css from './Tracking.module.css'
import { Icono } from '../Iconos/Icono';
import {EstiloEstado} from '../Scripts/Validaciones.js'

function Tracking(props) {
    function encabezado(texto){
        return (
            <div
            className={`${props.Marca} BackgroundColor TituloEntregaEstimada`}>
            {texto}
            </div>
        );
    };
    if (props.estado === -1) {
        return encabezado("No se encontró el pedido");
    } else if (props.estado === -2){
        return encabezado("Se presentó un error consultando el pedido");
    }
    else
        return (
            <div>
                {encabezado("Entrega estimada del pedido")}
                <div
                    className={`${css.contenedor} Color`}>
                    <div
                        className={`LineTimeBackground`}
                    >
                        <div
                            className=''>
                            <Icono
                                contenedorCirculo={`${css.contenedorCirculo}`}
                                border={css.borderFirst}
                                estilos={EstiloEstado(props.estado, 1)}
                                estadoActual={props.estado}
                                estado={1}>
                            </Icono>
                        </div>
                        <Icono
                            contenedorCirculo={`${css.contenedorCirculo}`}
                            estilos={EstiloEstado(props.estado, 2)}
                            
                            estadoActual={props.estado}
                            estado={2}>
                        </Icono>
                        <Icono
                            contenedorCirculo={`${css.contenedorCirculo}`}
                            estilos={EstiloEstado(props.estado, 3)}
                            estadoActual={props.estado}
                            
                            estado={3}>
                        </Icono>
                        <Icono
                            contenedorCirculo={`${css.contenedorCirculo}`}
                            estilos={EstiloEstado(props.estado, 4)}
                            estadoActual={props.estado}
                            
                            estado={4}>
                        </Icono>
                        <Icono
                            contenedorCirculo={`${css.contenedorCirculo}`}
                            estilos={EstiloEstado(props.estado, 5)}
                            estadoActual={props.estado}
                            estado={5}
                            border={css.borderLast}>
                        </Icono>
                    </div>
                    <div
                    >
                        <div
                            className={`${css.contendorEstado}`}>
                            <Aprobado
                                estilos={EstiloEstado(props.estado, 1)}
                                estado={props.estado}
                            >
                            </Aprobado>
                        </div>

                        <div
                            className={css.contendorEstado}>

                            <ConfirmacionPedido
                                estilos={EstiloEstado(props.estado, 2)}
                                contenedorCirculo={css.contenedorCirculo}
                                fechaEstimadaDespacho={props.orden.fechaEstimadaDesp && props.estado < 4 ? props.orden.fechaEstimadaDesp : null}
                                estado={props.estado}
                            >
                            </ConfirmacionPedido>
                        </div>

                        <div
                            className={css.contendorEstado}>

                            <Alistamiento
                                estilos={EstiloEstado(props.estado, 3)}
                                contenedorCirculo={css.contenedorCirculo}
                                estado={props.estado}
                            >
                            </Alistamiento>
                        </div>

                        <div
                            className={css.contendorEstado}>
                            <Transito
                                estilos={EstiloEstado(props.estado, 4)}
                                guiaTrans={props.orden.guiaTrans ? props.orden.guiaTrans : null}
                                transportadora={props.orden.transportista ? props.orden.transportista : null}
                                fechaEstimadaEntrega={props.orden.fechaEstimadaEntrega && props.estado < 5 ? props.orden.fechaEstimadaEntrega : null}
                                estado={props.estado}
                            >
                            </Transito>
                        </div>

                        <div
                            className={css.contendorEstado}>

                            <Entregado
                                estilos={EstiloEstado(props.estado, 5)}
                                fechaEntrega={props.orden.fechaEntrega ? props.orden.fechaEntrega : null}
                                estado={props.estado}
                            >
                            </Entregado>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export { Tracking };