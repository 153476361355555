import React from 'react';
import './Marca.css'

function Marca(props) {
    return ( 
        <div className={'BackgroundImage'}>
            {/* <img src={props.logo} alt='Marca' className={props.imagenLogo}>
            </img> */}
            
        </div>
     );
}

export {Marca};