import './App.css';
import { Tracking } from './Tracking/Tracking';
import { obtenerMarcaDesdeUrl, obtenerCodigoOrden } from './Scripts/Validaciones.js'
import { useEffect, useState } from 'react';
import { Marca } from './LogoMarca/Marca';
import { obtenerOrder } from './Fetch/Peticiones';
import { valorEstado } from './Scripts/Validaciones';
import { Header } from './Header/Header.js';
import { InfoPedido } from './InfoPedido/InfoPedido.js';

function App() {

  const [orden, setOrden] = useState({});

  const [peticion, setPeticion] = useState(false);

  const [tipoTienda, setTipoTienda] = useState("");
  const [loading, setLoading] = useState(true);
  const [estado, setEstado] = useState(-99);


  useEffect(() => {
    AsignarValores();
  }, [loading]);

  async function AsignarValores() {
    await ObtenerDatos();
    setLoading(false);
  }
  
  const ObtenerDatos = async () => {
    var tipoTiendaEspecificado = obtenerMarcaDesdeUrl(window.location.hostname);

    var codigoOrden = obtenerCodigoOrden(window.location.pathname);
    
    var order = await obtenerOrder(codigoOrden);
    if (!order.error) {
      setOrden(order.datos);

      if (order.datos && order.datos.marca){
        setEstado(valorEstado(order.datos.estadoSeguimiento));
        tipoTiendaEspecificado = order.datos.marca.toUpperCase();
      }else
        setEstado(-1);
    } else {
      setEstado(-2);
    }
    setTipoTienda(tipoTiendaEspecificado);
  }
  if(loading){
    return (
      <div 
      className='App'>
        Cargando
      </div>
    )
  }
  else
  return (
    <div
      className={`App ${tipoTienda.replace(" ", '')}`}>
      <Header>
        <div
          className="contenedorLogo">
          {
            tipoTienda ?
              <Marca
                >
              </Marca>
              :
              null
          }
        </div>
      </Header>
      {<InfoPedido
        orden={orden}
        estado={estado}
      >
      </InfoPedido>
      }
      <Tracking
        Marca={tipoTienda}
        orden={orden}
        estado={estado}
      />
    </div>
  );
}

export default App;
