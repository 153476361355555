import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import css from './Icono.module.css'

function Icono(props) {
    return (
        
            <div
                className={`${props.contenedorCirculo}  ${props.estilos} ${props.border} `}>
                {
                    props.estado <= props.estadoActual ?
                    <div
                        className={css.posicionIcono}>
                        <CheckCircleIcon
                            className={`${props.estilos} ${props.border}`}
                            sx={{ fontSize: 30 }}>
                        </CheckCircleIcon>
                    </div>
                        
                        :
                        <div
                            className={css.posicionIcono}>
                            <CircleRoundedIcon
                            className={`${props.estilos} ${props.border}`}
                            sx={{ fontSize: 30 }}>
                        </CircleRoundedIcon>
                        </div>
                        
                }
            </div>
        
    );
}

export { Icono };