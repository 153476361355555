import React from "react";

function Header(props) {
    return (
        <div
            className="BackgroundColor">
                {props.children}
        </div>
    );
}

export {Header}